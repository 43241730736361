import { BaseParameterTypes } from '@go-widgets/well-logs-widget';

import { WellStatuses } from './api/types';

export const statusesSortStructure = [
  WellStatuses.ACTIVE_GREEN,
  WellStatuses.ACTIVE_YELLOW,
  WellStatuses.ACTIVE_RED,
  WellStatuses.COMPLETED,
  WellStatuses.BAD,
];

export const paramsSortStructure = [
  BaseParameterTypes.AUTO_RECOGNIZED_EVENTS,
  BaseParameterTypes.AUTO_RECOGNIZED_OPERATIONS,
  BaseParameterTypes.DEPTH_HOLE,
  BaseParameterTypes.DEPTH_BIT,
  BaseParameterTypes.BLOCK_POSITION,
  BaseParameterTypes.HOOKLOAD,
  BaseParameterTypes.WEIGHT_ON_BIT,
  BaseParameterTypes.ROTARY_SPEED,
  BaseParameterTypes.ROTARY_TORQUE,
  BaseParameterTypes.MUD_FLOW_IN,
  BaseParameterTypes.STANDPIPE_PRESSURE,
  BaseParameterTypes.RATE_OF_PENETRATION,
  BaseParameterTypes.MAGNETIC_TOOLFACE,
  BaseParameterTypes.GRAVITY_TOOLFACE,
];
