import { VideoBroadcastWidget } from '@go-widgets/well-logs-widget';
import { FullArrowLight as FullIcon, ReduceLight as MinimizeIcon } from '@profgeosoft-ui/icons';
import { Tooltip, Button } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { VideobroadcastWidgetEntity } from './VideobroadcastWidget.entity';
import type { TWidgetRendererOptions } from '../workspace/services/WidgetRendererService';
import type { VideoBroadcastStateParams } from '@go-widgets/well-logs-widget';
import type { TCreateWidgetOptions } from 'src/entities/tab/TabEntity';

import { useService } from '../../../../packages/di';
import { NoWellChosen } from '../no-well-chosen';
import styles from '../operational-parameters-widget/OperationalParametersWidget.module.scss';
import { WidgetHeader } from '../widget-header/WidgetHeader';

import { VideobroadcastWidgetStore } from './VideobroadcastWidget.store';

type WrapperProps = {
  entity: VideobroadcastWidgetEntity;
  options: TWidgetRendererOptions;
  onCreateVideobroadcastWidget(
    wellId: number | null,
    stateParams: Partial<VideoBroadcastStateParams> | null,
    options?: TCreateWidgetOptions
  ): void;
};

type Props = {
  store: VideobroadcastWidgetStore;
  entity: VideobroadcastWidgetEntity;
  options: TWidgetRendererOptions;
};
export const VideobroadcastWidgetWrapper = observer(function VideobroadcastWidgetWrapper({
  entity,
  options,
  onCreateVideobroadcastWidget,
}: WrapperProps) {
  const [store, setStore] = useState<VideobroadcastWidgetStore | null>(null);
  const storeService = useService('widgetStoreService');

  useEffect(() => {
    if (store) return;

    const _store = storeService.getStore<VideobroadcastWidgetEntity, VideobroadcastWidgetStore>(entity);

    if (_store) {
      setStore(_store);
    } else {
      const newStore = new VideobroadcastWidgetStore(entity, options.groupSelectStore, onCreateVideobroadcastWidget);

      storeService.setStore<VideobroadcastWidgetEntity, VideobroadcastWidgetStore>(entity, newStore);

      setStore(newStore);
    }
  }, [setStore, storeService, entity, options.groupSelectStore, store, onCreateVideobroadcastWidget]);

  if (!store) return null;

  return <VideobroadcastWidget store={store} entity={entity} options={options} />;
});

export const VideobroadcastWidget = observer(function VideobroadcastWidget({ entity, store, options }: Props) {
  const { setGroupForWellLogs, isReady, effect, exitFullScreen } = store;
  const [wrapperElement, setWrapperElement] = useState<HTMLDivElement | null>(null);

  const { t } = useTranslation();

  const { wellId, isFullscreen, state, setFullscreen } = entity;

  useEffect(effect, [effect]);

  const directories = useService('directories');
  const wells = directories.getObject('GOdashboard_Well');
  const wellName = wells?.find((item) => item.id === wellId)?.data.name ?? wellId;

  return (
    <div className={styles.widgetWrapper} ref={(ref) => setWrapperElement(ref)}>
      <WidgetHeader
        entity={entity}
        onWidgetChangeGroup={setGroupForWellLogs}
        groupSelectStore={options.groupSelectStore}
        widgetName={entity.title}
        wellName={wellName}
        onWidgetDelete={() => {
          options.onWidgetDelete(entity);
        }}
        showCloseButton={!isFullscreen}
        draggable={!isFullscreen}
        renderAdditionalButtons={() => {
          if (!isReady) return null;

          return (
            <>
              {!isFullscreen && (
                <Tooltip title={t('common:Widget.fullscreen')} delay={400}>
                  <Button
                    onClick={() => setFullscreen(true)}
                    variant="flat"
                    icon={<FullIcon className={styles.icon} />}
                    className={styles.button}
                  />
                </Tooltip>
              )}
              {isFullscreen && (
                <Tooltip title={t('common:Widget.collapse')} delay={400}>
                  <Button
                    onClick={() => exitFullScreen()}
                    variant="flat"
                    icon={<MinimizeIcon className={styles.icon} />}
                    className={styles.button}
                  />
                </Tooltip>
              )}
            </>
          );
        }}
      />
      {wrapperElement &&
        (state ? (
          <div className={styles.wrapper}>
            {state && <VideoBroadcastWidget widgetState={state} externalContainer={wrapperElement} />}
          </div>
        ) : (
          <NoWellChosen />
        ))}
    </div>
  );
});
