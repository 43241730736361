import type {
  TWellBroadcastWidgetSerializedInternalState,
  ImageBroadcastWidgetEntity,
} from 'src/pages/dashboard/features/image-broadcast-widget/ImageBroadcastWidget.entity';
import type { TSerializedWidgetTemplate } from 'src/pages/dashboard/features/workspace/Workspace.store';

export function serializeImageBroadcastTemplate(widget: ImageBroadcastWidgetEntity): TSerializedWidgetTemplate {
  const internalState: TWellBroadcastWidgetSerializedInternalState = {
    wellId: widget.wellId,
    stateParams: {
      wellboreId: widget.stateParams.wellboreId,
      isPlaying: widget.stateParams.isPlaying,
      broadcastId: widget.stateParams.broadcastId,
      timeZone: widget.stateParams.timeZone,
    },
  };

  return {
    uuid: widget.uuid,
    type: widget.type,
    groupId: widget.groupId,
    size: {
      w: widget.size.w,
      h: widget.size.h,
    },
    position: {
      x: widget.position.x,
      y: widget.position.y,
    },
    internalState,
  };
}
