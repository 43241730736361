import { action, computed, flow, makeObservable, observable } from 'mobx';

import type { IBaseParameter, IWell, IWellbore, IWellLog, BaseParameterTypes, WellStatuses } from './api/types';
import type { BasicParametersEntity } from './BasicParameters.entity';
import type { Stack } from '../../../../packages/data/structures/Stack';
import type { TOption } from '../../../dashboard/features/controls/types';
import type { BaseSettingsScreen } from '../screen/BaseSettingsScreen';

import { requireService } from '../../../../packages/di';

import { BaseParametersApi } from './api/BaseParameters.api';
import { LogsIndex } from './api/types';
import { WellApi } from './api/Wells.api';
import { statusesSortStructure } from './consts';
import { ParameterLine } from './ParameterLine.store';

interface WellOption extends TOption {
  status: WellStatuses;
}

interface IClipboardParam {
  supplierId: number | string;
  logId: number | string;
  parameterId: number | string;
  parameterType: BaseParameterTypes;
}
export interface IClipboard {
  type: LogsIndex | null;
  parameters: IClipboardParam[];
}

export class BasicParametersStore {
  readonly stack: Stack<BaseSettingsScreen>;
  readonly screenEntity: BasicParametersEntity;
  private readonly wellApi: WellApi;
  private readonly baseParametersApi: BaseParametersApi;
  private readonly notifications = requireService('notifications');
  readonly isAltNames: boolean;

  @observable wells: IWell[] = [];
  @observable wellbores: IWellbore[] = [];
  @observable wellLogs: IWellLog[] = [];

  @observable currentWell: IWell | null = null;
  @observable currentWellbore: IWellbore | null = null;

  @observable paramsLines: ParameterLine[] = [];

  @observable isInitialised: boolean = false;
  @observable isWellboresLoading: boolean = false;
  @observable isWellsLoading: boolean = false;
  @observable isWellLogsLoading: boolean = false;
  @observable isBaseParamsLoading: boolean = false;
  @observable isApplyingToAllBoreholess: boolean = false;

  @observable clipboard: IClipboard = {
    type: null,
    parameters: [],
  };

  @observable logsIndex: LogsIndex = LogsIndex.TIME;

  constructor(stack: Stack<BaseSettingsScreen>, screen: BasicParametersEntity) {
    this.stack = stack;
    this.screenEntity = screen;
    this.wellApi = new WellApi();
    this.baseParametersApi = new BaseParametersApi();
    this.isAltNames = localStorage.getItem('altNames') === 'true';
    makeObservable(this);
  }

  @computed
  get wellsOptions(): WellOption[] {
    const options = this.wells.map((well) => {
      return {
        status: well.status,
        label: well.name,
        value: well.id,
      };
    });

    return options.sort(
      (a, b) =>
        statusesSortStructure.indexOf(a.status) - statusesSortStructure.indexOf(b.status) ||
        a.label.localeCompare(b.label)
    );
  }

  @computed
  get wellboresOptions(): TOption[] {
    return this.wellbores
      .map((wellbore) => {
        return {
          label: wellbore.name,
          value: wellbore.id,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  @action.bound
  setCurrentWell(id: number | string | null) {
    const well = this.wells.find((well) => well.id === Number(id));
    if (well) {
      this.currentWell = well;
      this.fetchWellbores();
    } else {
      this.currentWell = null;
    }

    this.paramsLines = [];
  }

  @action.bound
  setCurrentWellbore(id: number | string | null) {
    const wellbore = this.wellbores.find((wellbore) => wellbore.id === Number(id));
    if (wellbore) {
      this.currentWellbore = wellbore;
      this.fetchWellLogs();
    } else {
      this.currentWellbore = null;
    }
  }

  @action.bound
  setLogsIndex(index: LogsIndex) {
    if (index !== this.logsIndex) {
      this.logsIndex = index;
      this.fetchWellLogs();
    }
  }

  @action.bound
  saveToClipboard() {
    const copyParams = this.paramsLines.reduce<IClipboardParam[]>((acc, line) => {
      if (line.supplier.value && line.logNameId && line.parameterId) {
        acc.push({
          supplierId: line.supplier.value.id,
          logId: line.logNameId,
          parameterId: line.parameterId,
          parameterType: line.parameterType,
        });
      }
      return acc;
    }, []);
    this.clipboard = { parameters: copyParams, type: this.logsIndex };

    this.notifications.showSuccessMessageT('settings:dataCopied');
  }

  @action.bound
  async pasteFromClipboard() {
    if (this.currentWellbore) {
      if (this.logsIndex === this.clipboard.type) {
        const noParametersLines: BaseParameterTypes[] = [];
        const baseParams = this.paramsLines.reduce((paramsAcc: IBaseParameter[], param) => {
          const copiedParam = this.clipboard.parameters.find((copied) => copied.parameterType === param.parameterType);
          if (copiedParam) {
            if (copiedParam.logId === 'noName') {
              noParametersLines.push(param.parameterType);
            } else {
              let curveId = null;
              this.wellLogs.forEach((log) => {
                if (log.logName?.id === copiedParam.logId && log.supplier?.id === copiedParam.supplierId) {
                  const curve = log.curves.find((c) => c.parameter.id === copiedParam.parameterId);

                  if (curve) {
                    curveId = curve.goStreamId;
                    return;
                  }
                }
              });

              if (curveId) {
                paramsAcc.push({
                  logType: this.logsIndex,
                  curveId: Number(curveId),
                  wellboreId: Number(this.currentWellbore?.id),
                  parameter: param.parameterType,
                });
              } else {
                noParametersLines.push(param.parameterType);
              }
            }
          }
          return paramsAcc;
        }, []);

        await this.updateMultiBaseParameters(baseParams).finally(() => {
          this.paramsLines.forEach((pl) => {
            if (noParametersLines.includes(pl.parameterType)) {
              pl.setIsNoParameter(true);
            }
          });
        });
      }
    }
  }

  @action.bound
  createParamsLines(paramsApi: IBaseParameter[]) {
    this.paramsLines = paramsApi
      .sort((a, b) => a.parameter.localeCompare(b.parameter))
      .reduce((acc: ParameterLine[], param) => {
        const paramLine = new ParameterLine(param, this.updateBaseParameter, this.isAltNames);
        acc.push(paramLine);
        paramLine.updateWellLogs(this.wellLogs);
        if (param.curveId) {
          paramLine.setMnemonicFromBaseParams();
        }
        return acc;
      }, []);
  }

  @action.bound
  baseParamsFilteredByIndex(params: IBaseParameter[]) {
    return params.filter((p) => p.logType === this.logsIndex);
  }

  @flow.bound
  async *applyToAllWellbores() {
    try {
      this.isApplyingToAllBoreholess = true;
      if (this.currentWell) {
        for (let i = 0; i < this.wellbores.length; i += 1) {
          if (this.wellbores[i] === this.currentWellbore) {
            continue;
          }
          const logs = await this.wellApi.getLogs(this.currentWell.id, this.wellbores[i].id, this.logsIndex);
          const baseParams: IBaseParameter[] = [];
          yield;
          this.paramsLines.forEach((pl) => {
            if (pl.parameterId && pl.curveId && pl.logNameId) {
              const targetLog = logs.find((log) => log.logName?.id === pl.logNameId);
              const curve = targetLog?.curves.find((c) => c.parameter.id === pl.parameterId);
              if (curve) {
                baseParams.push({
                  logType: this.logsIndex,
                  wellboreId: this.wellbores[i].id,
                  parameter: pl.parameterType,
                  curveId: curve.goStreamId,
                });
              }
            }
          });
          await this.baseParametersApi.updateBaseParameter(baseParams);
          yield;
        }
      }
    } catch (error) {
      this.isApplyingToAllBoreholess = false;
      yield;

      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadBoreholesData');
    } finally {
      this.isApplyingToAllBoreholess = false;
      this.notifications.showSuccessMessageT('settings:applyToAllBoreHoles');
    }
  }

  @flow.bound
  async *fetchWells() {
    try {
      this.isWellsLoading = true;
      const wells = await this.wellApi.getWells();
      yield;
      this.wells = wells;
      this.isInitialised = true;
    } catch (error) {
      yield;
      this.isWellsLoading = false;
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadWells');
    } finally {
      this.isWellsLoading = false;
    }
  }

  @flow.bound
  async *fetchWellbores() {
    try {
      this.isWellboresLoading = true;

      if (this.currentWell) {
        const wellbores = await this.wellApi.getWellbores(this.currentWell.id);
        yield;
        this.wellbores = wellbores;

        this.setCurrentWellbore(this.wellbores[0] ? this.wellbores[0].id : null);
      }
    } catch (error) {
      yield;
      this.isWellboresLoading = false;
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadBoreholesData');
    } finally {
      this.isWellboresLoading = false;
    }
  }

  @flow.bound
  async *fetchWellLogs() {
    try {
      this.isWellLogsLoading = true;

      if (this.currentWell && this.currentWellbore) {
        const wellLogs = await this.wellApi.getLogs(this.currentWell.id, this.currentWellbore.id, this.logsIndex);
        yield;
        this.wellLogs = wellLogs;
        this.fetchBaseParameters();
        yield;
      }
    } catch (error) {
      yield;
      this.isWellLogsLoading = false;
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadData');
    } finally {
      this.isWellLogsLoading = false;
    }
  }

  @flow.bound
  async *fetchBaseParameters() {
    try {
      this.isBaseParamsLoading = true;
      if (this.currentWellbore) {
        const baseParameters = await this.baseParametersApi.getBaseParameter(this.currentWellbore.id);
        yield;
        this.createParamsLines(this.baseParamsFilteredByIndex(baseParameters));
      }
    } catch (error) {
      yield;
      this.isBaseParamsLoading = false;
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadBaseParameters');
    } finally {
      this.isBaseParamsLoading = false;
    }
  }

  @flow.bound
  async *updateBaseParameter(setLoading: (value: boolean) => void, parameter: BaseParameterTypes, curveId?: number) {
    try {
      if (this.currentWellbore) {
        setLoading(true);
        const baseParams: IBaseParameter = {
          logType: this.logsIndex,
          wellboreId: Number(this.currentWellbore.id),
          parameter: parameter,
        };
        if (curveId) {
          baseParams.curveId = curveId;
        }
        await this.baseParametersApi.updateBaseParameter([baseParams]);
        yield;
      }
    } catch (error) {
      yield;
      setLoading(false);
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToUpdateBaseParameters');
    } finally {
      setLoading(false);
    }
  }

  @flow.bound
  async *updateMultiBaseParameters(baseParams: IBaseParameter[]) {
    try {
      this.isBaseParamsLoading = true;
      await this.baseParametersApi.updateBaseParameter(baseParams);
      yield;
      await this.fetchBaseParameters();
      yield;
    } catch (error) {
      yield;
      this.isBaseParamsLoading = false;
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToUpdateBaseParameters');
    } finally {
      this.isBaseParamsLoading = false;
    }
  }
}
